import React from 'react';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { classes, st } from './BookingDetails.st.css';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../settingsParams';
import { getContent } from '../../../../utils/content/content';
import { ServiceData } from '../../../../utils/state/types';
import { FormSelectedSlot } from '../../../../types/formSelectedSlots';
import { getServiceSlotIdentifier } from '../../../../utils';
import SingleSlotDetails from './SingleSlotDetails/SingleSlotDetails';
import { Accordion, Divider } from 'wix-ui-tpa/cssVars';
import { BookingDetailsDataHook } from './dataHooks';
import Text, { TextType } from '../../Text/Text';
import { ExperimentsConsts } from '../../../../consts/experiments';

export interface BookingDetailsProps {
  serviceData: ServiceData;
  formSelectedSlot: FormSelectedSlot;
  numberOfParticipants?: number;
}

const SERVICE_DETAILS_TITLE = 'service-details';

const BookingDetails: React.FC<BookingDetailsProps> = ({
  serviceData,
  formSelectedSlot,
  numberOfParticipants,
}) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const { experiments } = useExperiments();

  const sectionTitle = getContent({
    settings,
    settingsParam: settingsParams.summarySectionTitle,
  });

  const tagName = settings.get(settingsParams.summarySectionTagName);

  const isFormAddH1HeaderForA11yEnabled = experiments.enabled(
    ExperimentsConsts.FormAddH1HeaderForA11y,
  );

  const renderMobile = () => (
    <Accordion
      initiallyExpanded={isMobile ? '' : SERVICE_DETAILS_TITLE}
      className={
        isFormAddH1HeaderForA11yEnabled
          ? classes.accordion
          : classes.accordionOld
      }
      data-hook={BookingDetailsDataHook.ACCORDION}
    >
      <Accordion.Item
        id={SERVICE_DETAILS_TITLE}
        title={sectionTitle}
        titleTag={tagName}
      >
        {renderContent()}
      </Accordion.Item>
    </Accordion>
  );

  const renderDesktop = () => (
    <>
      <Text
        tagName={tagName}
        type={TextType.Title}
        data-hook={BookingDetailsDataHook.SECTION_TITLE}
      >
        {sectionTitle}
      </Text>
      <Divider className={st(classes.divider, classes.divider1)} />
      {renderContent()}
      <Divider className={st(classes.divider)} />
    </>
  );

  const renderContent = () =>
    formSelectedSlot.nestedSlots.map((slot, index, slots) => {
      const slotService =
        serviceData.slotServices[getServiceSlotIdentifier(slot)];
      return (
        <div className={index < slots.length ? classes.item : ''}>
          <SingleSlotDetails
            key={getServiceSlotIdentifier(slot)}
            isSingleService={serviceData.isSingleService}
            slot={slot}
            slotService={slotService}
            totalNumberOfParticipants={numberOfParticipants}
          />
        </div>
      );
    });

  return (
    <div className={st(classes.root, { isMobile })}>
      {!isFormAddH1HeaderForA11yEnabled || isMobile
        ? renderMobile()
        : renderDesktop()}
    </div>
  );
};

export default BookingDetails;
