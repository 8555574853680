import React from 'react';
import { FormValues } from '@wix/form-viewer';

export type FormComponentContextType = {
  wixFormValues: FormValues;
  isCartConflictError?: boolean;
  isUpsellPluginInstalled?: boolean;
  dateRegionalSettingsLocale: string;
};

const FormComponentContext = React.createContext<FormComponentContextType>(
  {} as FormComponentContextType,
);
export const FormComponentContextProvider = FormComponentContext.Provider;

export const useFormComponentContext = () =>
  React.useContext(FormComponentContext);
