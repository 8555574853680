import { useTranslation, TFunction } from '@wix/yoshi-flow-editor';
import { FormViewerHandle } from '@wix/form-viewer/widget';
import { getServiceSlotIdentifier, mapToArray } from '../../../utils';
import { ServiceData, SlotService } from '../../../utils/state/types';
import { PaymentOption } from '../../../types/types';
import { getMaxNumberOfParticipants } from '../../../utils/numberOfParticipant';
import { DynamicPriceInfo } from '../../../types/dynamicPrice';
import { DynamicPriceErrorType } from '../../../types/errors';
import { AddError } from '../Actions/errorHandlers/addError/addError';
import { FormControllerActions } from '../Actions/actions';

type ValidateWixForm = {
  actions: FormControllerActions;
  serviceData: ServiceData;
  wixFormRef: React.RefObject<FormViewerHandle | undefined>;
};

export default function useValidateWixForm() {
  const { t } = useTranslation();

  const validateWixForm = async ({
    actions,
    serviceData,
    wixFormRef,
  }: ValidateWixForm): Promise<boolean> => {
    const { addError } = actions;
    const isValidWixForm = (await wixFormRef?.current?.validate?.()) || false;

    if (isValidWixForm) {
      return ValidateBookingsForm({
        serviceData,
        addError,
        t,
      });
    }

    return isValidWixForm;
  };

  return {
    validateWixForm,
  };
}

const ValidateBookingsForm = ({
  serviceData,
  addError,
  t,
}: {
  serviceData: ServiceData;
  addError: AddError;
  t: TFunction;
}) => {
  if (!serviceData.isSingleService) {
    return true;
  }

  const singleSlotService = mapToArray<SlotService>(
    serviceData.slotServices,
  )[0];

  const { selectedPaymentOption } = singleSlotService;
  const isDynamicPricingCustom =
    !!singleSlotService.dynamicPriceInfo?.customOptions?.length;
  if (singleSlotService.dynamicPriceInfo && isDynamicPricingCustom) {
    return isDynamicPriceDropdownValid({
      dynamicPriceInfo: singleSlotService.dynamicPriceInfo,
      selectedPaymentOption,
      currentMaxNumberOfParticipants: getMaxNumberOfParticipants(
        serviceData,
        getServiceSlotIdentifier(singleSlotService.nestedSlot),
      ),
      t,
      addError,
    });
  }

  return true;
};

const isDynamicPriceDropdownValid = ({
  dynamicPriceInfo,
  selectedPaymentOption,
  currentMaxNumberOfParticipants,
  addError,
  t,
}: {
  dynamicPriceInfo: DynamicPriceInfo;
  selectedPaymentOption: PaymentOption;
  currentMaxNumberOfParticipants: number;
  addError: AddError;
  t: TFunction;
}) => {
  const selectedNumberOfParticipants =
    dynamicPriceInfo?.selectedVariants?.reduce(
      (acc, nextVariant) => acc + (nextVariant.numberOfParticipants || 0),
      0,
    ) || 0;

  const isAtLeastOneParticipantSelected = selectedNumberOfParticipants > 0;
  const isEqualToOrLessThanMaxParticipantsAllowed =
    selectedNumberOfParticipants <= currentMaxNumberOfParticipants;

  const isDropdownValid =
    isAtLeastOneParticipantSelected &&
    isEqualToOrLessThanMaxParticipantsAllowed;

  if (!isDropdownValid) {
    if (!isAtLeastOneParticipantSelected) {
      addError(DynamicPriceErrorType.MISSING_REQUIRED_FIELD_DATA, {
        message: t('app.form-inputs.validation-errors.required-field'),
      });
    } else {
      addError(DynamicPriceErrorType.NOT_ENOUGH_SESSIONS_FOR_DYNAMIC_PRICE, {
        message: t(
          'app.booking-details.price-not-enough-sessions-error-message',
          {
            planName: selectedPaymentOption.label,
          },
        ),
      });
    }
  }
  return isAtLeastOneParticipantSelected;
};
