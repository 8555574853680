import {
  Dropdown,
  DropdownOptionProps,
  DropdownTheme,
} from 'wix-ui-tpa/cssVars';
import React, { FC, useEffect, useState } from 'react';
import { ServiceData, SlotService } from '../../../../utils/state/types';
import { getMaxNumberOfParticipants } from '../../../../utils/numberOfParticipant';
import { getServiceSlotIdentifier, mapToArray } from '../../../../utils';
import { BenefitType } from '@wix/ambassador-pricing-plans-v2-benefit/types';
import { useFormActions } from '../../Hooks/useFormActions';
import { classes, st } from './NumberOfParticipantsDropdown.st.css';
import { useSettingsAdapter } from '../../Settings/Utils/SettingsAdapter';
import settingsParams from '../../settingsParams';
import { BorderStyle } from '../../../../types/types';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { NumberOfParticipantsDropdownDataHooks } from './datahooks';
import { getContent } from '../../../../utils/content/content';

export interface NumberOfParticipantsDropdownProps {
  serviceData: ServiceData;
  initialSelectedId?: string;
}

const createDropdownOptions = (
  maxNumberOfParticipants: number,
): DropdownOptionProps[] => {
  return Array.from({ length: maxNumberOfParticipants }, (_, index) => ({
    id: (index + 1).toString(),
    value: (index + 1).toString(),
    label: (index + 1).toString(),
    isSelectable: true,
  }));
};

const NumberOfParticipantsDropdown: FC<NumberOfParticipantsDropdownProps> = ({
  serviceData,
  initialSelectedId,
}) => {
  const singleService = mapToArray<SlotService>(serviceData.slotServices)[0];
  const { setNumberOfParticipants } = useFormActions();

  const maxNumberOfParticipants = getMaxNumberOfParticipants(
    serviceData,
    getServiceSlotIdentifier(singleService.nestedSlot),
  );

  const dropdownOptions = createDropdownOptions(maxNumberOfParticipants);

  const settings = useSettingsAdapter();

  const label = getContent({
    settings,
    settingsParam: settingsParams.numberOfParticipantsDropdownLabel,
  });

  const theme =
    settings.get(settingsParams.fieldsBorderStyle) === BorderStyle.UNDERLINE
      ? DropdownTheme.Line
      : DropdownTheme.Box;

  const { isMobile } = useEnvironment();

  const [selectedId, setSelectedId] = useState<string | undefined>(
    initialSelectedId || '1',
  );

  const { isVariedPricing } = singleService!.service.payment.paymentDetails;
  const doesServiceOptionsAndVariantsExists =
    singleService &&
    !!singleService?.dynamicPriceInfo?.serviceOptionsAndVariants;
  const isDynamicPrice = doesServiceOptionsAndVariantsExists && isVariedPricing;

  const { selectedPaymentOption } = singleService;

  const isPlanUnlimited =
    selectedPaymentOption?.benefitInfo?.benefit?.benefitType ===
    BenefitType.UNLIMITED;

  useEffect(() => {
    if (isPlanUnlimited && selectedId) {
      setSelectedId('1');
    }
  }, [isPlanUnlimited]);

  if (dropdownOptions.length === 1 || isDynamicPrice) {
    return null;
  }

  return (
    <div
      className={st(classes.root, {
        theme,
        isMobile,
      })}
    >
      <Dropdown
        data-hook={NumberOfParticipantsDropdownDataHooks.DROPDOWN}
        key={selectedId}
        upgrade
        className={classes.dropdown}
        theme={theme}
        options={dropdownOptions}
        disabled={isPlanUnlimited}
        initialSelectedId={selectedId}
        onChange={(option) => {
          setNumberOfParticipants(
            Number(option.value),
            getServiceSlotIdentifier(singleService.nestedSlot),
          );
          setSelectedId(option.id!);
        }}
        label={label}
        mobileNativeSelect={isMobile}
      />
    </div>
  );
};
export default NumberOfParticipantsDropdown;
