import React from 'react';
import { useExperiments } from '@wix/yoshi-flow-editor';
import { FlowType } from '../../../../../types/types';
import { ButtonAdapter } from '../ButtonAdapter';
import { SlotServices } from '../../../../../utils/state/types';
import { FormStatus } from '../../../../../types/form-state';
import { useSettings } from '@wix/tpa-settings/react';
import { getContent } from '../../../../../utils/content/content';
import settingsParams from '../../../settingsParams';
import { ButtonAdapterDataHooks } from '../dataHooks';

export interface BuyPlanButtonProps {
  slotServices: SlotServices;
  status: FormStatus;
}

export const BuyPlanButton: React.FC<BuyPlanButtonProps> = ({ status }) => {
  const { experiments } = useExperiments();
  const settings = useSettings();
  const label = getContent({
    settings,
    settingsParam: settingsParams.chooseAPlanText,
    experiments,
  });

  return (
    <ButtonAdapter
      skipValidation
      label={label}
      status={status}
      flowType={FlowType.CHOOSE_PLAN}
      dataHook={ButtonAdapterDataHooks.BUY_A_PLAN_CTA}
    />
  );
};
