import React from 'react';
import {
  SectionMessage,
  SectionMessageType,
} from '../SectionMessage/SectionMessage';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { classes } from './ServiceInCartNotification.st.css';
import { ServiceInCartNotificationDataHooks } from './datahooks';

export interface ServiceInCartNotificationProps {
  isServiceInCart?: boolean;
}

export const ServiceInCartNotification: React.FC<
  ServiceInCartNotificationProps
> = ({ isServiceInCart }) => {
  const { t } = useTranslation();

  if (!isServiceInCart) {
    return null;
  }

  return (
    <SectionMessage
      className={classes.root}
      type={SectionMessageType.Default}
      text={t('app.booking-form-notifications.service-already-in-cart.message')}
      withoutIcon
      dataHook={ServiceInCartNotificationDataHooks.MAIN}
    />
  );
};
