import { useTranslation } from '@wix/yoshi-flow-editor';
import React, { FC } from 'react';
import TextComponent, { TextType } from '../../Text/Text';
import { TagName } from '../../../../utils/tagName.const';
import { classes } from './SmsNotification.st.css';
import { SmsNotificationDataHooks } from './datahooks';

export interface SmsNotificationProps {
  isMSAutomationEnabled?: boolean;
}

const SmsNotification: FC<SmsNotificationProps> = ({
  isMSAutomationEnabled,
}) => {
  const { t } = useTranslation();

  if (!isMSAutomationEnabled) {
    return null;
  }

  return (
    <div className={classes.root} data-hook={SmsNotificationDataHooks.main}>
      <TextComponent
        type={TextType.Secondary}
        tagName={TagName.Paragraph}
        data-hook={SmsNotificationDataHooks.text}
      >
        {t('app.bookings-form.booking-details.booking-policy.sms-notification')}
      </TextComponent>
    </div>
  );
};

export default SmsNotification;
