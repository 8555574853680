import React from 'react';
import { FormControllerActions } from '../Actions/actions';

export type FormComponentActions = {
  validateWixForm: () => Promise<boolean>;
};

export type FormActions = FormControllerActions & FormComponentActions;

const FormActionsContext = React.createContext<FormActions>({} as FormActions);
export const FormActionsProvider = FormActionsContext.Provider;

export const useFormActions = () => React.useContext(FormActionsContext);
