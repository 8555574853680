import React, { useCallback, useState } from 'react';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { FormViewer, FormViewerHandle } from '@wix/form-viewer/widget';
import {
  FormError,
  FormStatus as WixFormStatus,
  FormValues,
  FORM_STATUS,
} from '@wix/form-viewer';
import { classes, st } from './WixForm.st.css';
import { useSettingsAdapter } from '../../Settings/Utils/SettingsAdapter';
import settingsParams from '../../settingsParams';
import { WixForms } from '../../../../utils/state/types';
import { useFormActions } from '../../Hooks/useFormActions';
import { WixFormDatahooks } from './datahooks';
import { FormStatus } from '../../../../types/form-state';

export interface WixFormProps {
  formId: string;
  wixForms: WixForms;
  formValues: FormValues;
  handleFormChange: (values: FormValues) => void;
  wixFormRef: React.RefObject<FormViewerHandle>;
}
const WixForm: React.FC<WixFormProps> = ({
  formId,
  formValues,
  handleFormChange,
  wixFormRef,
  wixForms,
}) => {
  const bi = useBi();
  const { setFormStatus } = useFormActions();
  const { i18n } = useTranslation();
  const [formErrors, setFormErrors] = useState<FormError[]>([]);
  const { isMobile } = useEnvironment();

  const handleFormValidate = useCallback((errors: FormError[]) => {
    setFormErrors(errors);
  }, []);

  const handleStatusChanged = useCallback((status: WixFormStatus) => {
    if (status === FORM_STATUS.BUSY) {
      setFormStatus(FormStatus.GENERAL_PROCESS);
    } else {
      setFormStatus(FormStatus.IDLE);
    }
  }, []);

  const settings = useSettingsAdapter();
  const theme = settings.get(settingsParams.fieldsBorderStyle);

  return (
    <div
      data-hook={WixFormDatahooks.Main}
      className={st(classes.root, { theme, isMobile })}
    >
      <FormViewer
        onStatusChange={(status) => handleStatusChanged(status)}
        onChange={handleFormChange}
        onValidate={handleFormValidate}
        values={formValues}
        errors={formErrors}
        i18n={i18n}
        formId={formId}
        bi={bi}
        ref={wixFormRef}
        overrides={wixForms.overrides}
      />
    </div>
  );
};

export default WixForm;
