import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import { ButtonAdapter } from '../ButtonAdapter';
import { FormStatus } from '../../../../../types/form-state';
import { ButtonAdapterDataHooks } from '../dataHooks';
import { CartFlow } from '../../../../../types/types';
import { getContent } from '../../../../../utils/content/content';
import settingsParams from '../../../settingsParams';
import { useExperiments } from '@wix/yoshi-flow-editor';
import { useFormComponentContext } from '../../../Hooks/useFormComponentContext';

export interface CartBookButtonsProps {
  status: FormStatus;
}

export const AddCartButton: React.FC<CartBookButtonsProps> = ({ status }) => {
  const { experiments } = useExperiments();
  const settings = useSettings();
  const { isCartConflictError } = useFormComponentContext();

  const addToCartLabel = getContent({
    settings,
    settingsParam: settingsParams.addToCartText,
    experiments,
  });

  return (
    <ButtonAdapter
      label={addToCartLabel}
      dataHook={ButtonAdapterDataHooks.ADD_TO_CART_CTA}
      cartFlow={CartFlow.ADD_MORE_SESSIONS}
      status={status}
      disabled={isCartConflictError}
    />
  );
};
