import React from 'react';
import { PaymentSectionContainerHook } from './dataHooks';
import { classes } from './PaymentSelectionContainer.st.css';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { ServiceData } from '../../../../utils/state/types';
import { getMultiPaymentSelectionContainer } from './MultiPaymentSelectionContainer/MultiPaymentSelectionContainer';
import { FormStatus } from '../../../../types/form-state';
import { FormError } from '../../../../types/errors';
import { useFormComponentContext } from '../../Hooks/useFormComponentContext';

export type PaymentSelectionContainerProps = {
  serviceData: ServiceData;
  memberDetails?: Member;
  numberOfParticipants: number;
  isPricingPlanInstalled: boolean;
  status: FormStatus;
  errors: FormError[];
};

export const PaymentSelectionContainer: React.FC<
  PaymentSelectionContainerProps
> = (props) => {
  const { dateRegionalSettingsLocale } = useFormComponentContext();

  const componentToShow = getMultiPaymentSelectionContainer({
    ...props,
    dateRegionalSettingsLocale,
  });
  return (
    componentToShow && (
      <fieldset
        className={classes.root}
        data-hook={PaymentSectionContainerHook.Payment_Section}
      >
        {componentToShow}
      </fieldset>
    )
  );
};
