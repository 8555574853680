import { SelectedVariants } from '@wix/bookings-uou-types';
import { PriceUtils } from '@wix/bookings-uou-mappers';
import { SlotService } from '../../../state/types';
import { TFunction } from '../../../../types/types';
import Experiments from '@wix/wix-experiments';

const findVariantPrice = (
  slotService: SlotService,
  selectedVariant?: SelectedVariants,
) => {
  return slotService.dynamicPriceInfo?.serviceOptionsAndVariants?.variants?.values?.find(
    (value) =>
      value.choices?.find((choice) => {
        if (choice.custom) {
          return choice.custom === selectedVariant?.choices?.[0].custom;
        }
        if (choice.staffMemberId) {
          return (
            choice.staffMemberId === selectedVariant?.choices?.[0].staffMemberId
          );
        }
        if (choice.duration) {
          return (
            choice.duration.minutes ===
            selectedVariant?.choices?.[0].duration?.minutes
          );
        }
        return false;
      }),
  )?.price;
};

export const getPriceLineText = ({
  slotService,
  dateRegionalSettingsLocale,
  t,
  selectedVariant,
  totalNumberOfParticipants = 1,
  experiments,
}: {
  slotService: SlotService;
  dateRegionalSettingsLocale: string;
  t: TFunction;
  selectedVariant?: SelectedVariants;
  totalNumberOfParticipants?: number;
  experiments: Experiments;
}) => {
  const { paymentDetails } = slotService;

  let priceText = '';

  if (selectedVariant) {
    const price = findVariantPrice(slotService, selectedVariant);

    priceText = `${PriceUtils.getFormattedCurrency({
      price: Number(price?.value),
      currency: price?.currency!,
      locale: dateRegionalSettingsLocale,
    })}${
      selectedVariant?.choices?.[0].custom
        ? ` (${selectedVariant?.choices?.[0].custom})`
        : ''
    }${
      totalNumberOfParticipants > 1
        ? ' ' +
          t(
            'app.bookings-form.service-details.dynamic-price.number-of-participants',
            { numberOfParticipants: selectedVariant?.numberOfParticipants },
          )
        : ''
    }`;
  } else {
    const price = PriceUtils.getFormattedCurrency({
      price: paymentDetails.minPrice?.price!,
      currency: paymentDetails.currency,
      locale: dateRegionalSettingsLocale,
    });

    if (
      paymentDetails.isVariedPricing ||
      Number(paymentDetails.minPrice?.price)
    ) {
      priceText =
        paymentDetails.isVariedPricing &&
        !slotService?.dynamicPriceInfo?.selectedPreferences?.length
          ? t('app.payment.from-price.text', { price })
          : price;

      if (totalNumberOfParticipants > 1) {
        priceText = `${priceText} ${t(
          'app.bookings-form.service-details.dynamic-price.number-of-participants',
          { numberOfParticipants: totalNumberOfParticipants },
        )}`;
      }
    }

    if (paymentDetails.isFree) {
      if (paymentDetails.priceText) {
        priceText = paymentDetails.priceText;
      } else {
        priceText = t('app.bookings-form.service-details.free-service');
      }
    }
  }

  if (paymentDetails.minCharge) {
    priceText = `${priceText} (${t('app.payment.summary.deposit.text', {
      price: PriceUtils.getFormattedCurrency({
        price: paymentDetails.minCharge,
        currency: paymentDetails.currency,
        locale: dateRegionalSettingsLocale,
      }),
    })})`;
  }

  return priceText;
};
