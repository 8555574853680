import React, { FC } from 'react';
import { useSettingsAdapter } from '../../Settings/Utils/SettingsAdapter';
import Text, { TextType } from '../../Text/Text';
import { getContent } from '../../../../utils/content/content';
import settingsParams from '../../settingsParams';
import { classes, st } from './Header.st.css';
import { useExperiments } from '@wix/yoshi-flow-editor';
import { ExperimentsConsts } from '../../../../consts/experiments';
import { HeaderDataHooks } from './datahooks';

export interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
  const settings = useSettingsAdapter();
  const textAlignment = settings.get(settingsParams.titlesAlignment);
  const tagName = settings.get(settingsParams.pageTitleTagName);
  const { experiments } = useExperiments();

  const isFormAddH1HeaderForA11yEnabled = experiments.enabled(
    ExperimentsConsts.FormAddH1HeaderForA11y,
  );

  if (!isFormAddH1HeaderForA11yEnabled) {
    return null;
  }

  return (
    <div className={st(classes.root, { textAlignment })}>
      <Text
        tagName={tagName}
        type={TextType.Header}
        data-hook={HeaderDataHooks.TITLE}
      >
        {getContent({ settings, settingsParam: settingsParams.pageTitle })}
      </Text>
    </div>
  );
};

export default Header;
