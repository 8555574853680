import React from 'react';
import { classes, st } from './ButtonAdapter.st.css';
import { ButtonAdapterDataHooks } from '../dataHooks';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import {
  Button,
  ButtonSize as SIZE,
  Spinner,
  ButtonPriority,
  HoverStyles,
} from 'wix-ui-tpa/cssVars';
import { useFormActions } from '../../../Hooks/useFormActions';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import settingsParams from '../../../settingsParams';
import stylesParams from '../../../stylesParams';
import { CartFlow, FlowType } from '../../../../../types/types';
import { FormStatus } from '../../../../../types/form-state';
import { useFormComponentContext } from '../../../Hooks/useFormComponentContext';

export interface ButtonAdapterProps {
  label: string;
  status: FormStatus;
  cartFlow?: CartFlow;
  flowType?: FlowType;
  loading?: boolean;
  dataHook: ButtonAdapterDataHooks;
  primary?: boolean;
  skipValidation?: boolean;
  disabled?: boolean;
}

export const ButtonAdapter: React.FC<ButtonAdapterProps> = ({
  dataHook,
  label,
  status,
  cartFlow,
  flowType,
  loading,
  primary = true,
  disabled = false,
  skipValidation = false,
}) => {
  const { t } = useTranslation();
  const { wixFormValues } = useFormComponentContext();
  const { onSubmit, validateWixForm } = useFormActions();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const styles = useStyles();
  const fontSize = styles.get(stylesParams.mainButtonFont).size!;

  return (
    <div className={st(classes.root, { isMobile })}>
      <Button
        hoverStyle={HoverStyles.underline}
        size={SIZE.medium}
        className={st(classes.bookButton, {
          primary,
        })}
        data-hook={dataHook}
        upgrade
        aria-pressed={loading}
        type="submit"
        priority={primary ? ButtonPriority.primary : ButtonPriority.basic}
        fullWidth={settings.get(settingsParams.stretchButtonToFullWidth)}
        onClick={async () => {
          let wixFormValid = true;

          if (!skipValidation) {
            wixFormValid = await validateWixForm();
          }

          if (wixFormValid) {
            onSubmit({ wixFormValues, flowType, cartFlow });
          }
        }}
        disabled={status !== FormStatus.IDLE || disabled}
      >
        {loading ? (
          <div className={classes.spinnerWrapper}>
            <Spinner
              data-hook={ButtonAdapterDataHooks.SPINNER}
              className={classes.spinner}
              diameter={fontSize}
              isCentered
            />
          </div>
        ) : (
          t(label)
        )}
      </Button>
    </div>
  );
};
